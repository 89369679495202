<script>
import { SuawCallout, SuawButton } from "@suaw/suaw-component-library";
export default {
  name: "BecomeOrganizer",
  components: { SuawCallout, SuawButton },
  methods: {
    onBecomeOrganizerClicked() {
      this.$router.push({
        name: "Resources-BecomeAnOrganizer"
      });
    }
  }
};
</script>

<template>
  <SuawCallout
    layout="center"
    calloutImage=""
    calloutHeading="Can't find an event that works for you?"
    calloutDesc="Host your own event by becoming a Shut Up & Write! organizer."
  >
    <template #actions>
      <SuawButton size='large' type='primary' buttonText='Become an Organizer' @click="onBecomeOrganizerClicked" />
    </template>
  </SuawCallout>
</template>